import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Box, Icon } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const serviceData = [
  {
    title: 'Danışmanlık',
    description: 'KVKK Kanun Hükümleri kapsamında işletmelerin uyum sürecinde ihtiyaç duyabileceği teknik ve idari danışmanlıkları kendi bünyemizde, hukuki danışmanlık hizmetleri hukuk bürosu iş ortaklarımızla birlikte sunuyoruz',
    icon: SecurityIcon,
    color: '#2563eb'
  },
  {
    title: 'Eğitim',
    description: "İşletmelerin KVKK'ya uyum sürecinde ihtiyaç duyacakları eğitimleri veriyor, çalışanlarınızın bilinçlendirilmesi için özel KVKK ve bilgi güvenliği farkındalık eğitim programları düzenliyoruz.",
    icon: SchoolIcon,
    color: '#0891b2'
  },
  {
    title: 'Bilgi Güvenliği',
    description: 'Kişisel verilerin korunması için gerekli olan bilgi güvenliği altyapısının kurulması ve yönetilmesi konusunda destek sağlıyoruz.',
    icon: VerifiedUserIcon,
    color: '#0d9488'
  }
];

const Services = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        bgcolor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background decoration */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          background: 'radial-gradient(circle at 0% 0%, rgba(37, 99, 235, 0.1) 0%, transparent 50%)',
          zIndex: 0,
        }}
      />
      
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{
            mb: 6,
            background: 'linear-gradient(to right, #1e40af, #2563eb)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Hizmetlerimiz
        </Typography>
        
        <Grid container spacing={4}>
          {serviceData.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)',
                  },
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '150px',
                    height: '150px',
                    background: `radial-gradient(circle at 100% 0%, ${service.color}15 0%, transparent 70%)`,
                    borderRadius: '0 0 0 100%',
                  }}
                />
                
                <CardContent sx={{ p: 4, flex: 1, zIndex: 1 }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      p: 2,
                      borderRadius: '12px',
                      bgcolor: `${service.color}10`,
                      color: service.color,
                      mb: 3,
                    }}
                  >
                    <service.icon sx={{ fontSize: 40 }} />
                  </Box>
                  
                  <Typography
                    variant="h5"
                    component="h3"
                    gutterBottom
                    sx={{ color: 'text.primary', fontWeight: 600 }}
                  >
                    {service.title}
                  </Typography>
                  
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ lineHeight: 1.7 }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
