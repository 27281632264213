import React from 'react';
import { Box, Container, Typography, Button, Grid, Stack } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import BusinessIcon from '@mui/icons-material/Business';
import ShieldIcon from '@mui/icons-material/Shield';

const Hero = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(rgba(37, 99, 235, 0.9), rgba(30, 64, 175, 0.95)), url("/images/banner_gnc.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        pt: { xs: 12, md: 20 },
        pb: { xs: 12, md: 20 },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={7}>
            <Box sx={{ position: 'relative', zIndex: 2 }}>
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  mb: 3,
                  background: 'linear-gradient(to right, #ffffff, #e2e8f0)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                KVKK ve Bilgi Güvenliği Danışmanlığı
              </Typography>

              <Stack spacing={3}>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    lineHeight: 1.6,
                    maxWidth: '600px',
                  }}
                >
                  GNC Veri Teknolojileri, Kişisel Verilerin Korunması Kanunu (KVKK) ve Veri Güvenliği hakkında işletmelere yönelik ve uyum sürecinde danışmanlık hizmeti sağlamaktadır.
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.95)',
                    lineHeight: 1.6,
                    maxWidth: '600px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                  }}
                >
                  <ShieldIcon sx={{ mt: 0.5, fontSize: 28 }} />
                  Kişisel verilerin korunması için gerekli olan bilgi güvenliği altyapısının kurulması ve yönetilmesi konusunda destek sağlıyoruz.
                </Typography>

                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      bgcolor: 'white',
                      color: 'primary.main',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                    startIcon={<SecurityIcon />}
                    onClick={() => {
                      const element = document.getElementById('services');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  >
                    Hizmetlerimiz
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      borderColor: 'white',
                      color: 'white',
                      '&:hover': {
                        borderColor: 'rgba(255, 255, 255, 0.9)',
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                    startIcon={<BusinessIcon />}
                    onClick={() => {
                      const element = document.getElementById('contact');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  >
                    İletişime Geçin
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '400px',
                  height: '400px',
                  background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
                  borderRadius: '50%',
                },
              }}
            >
              {/* Add a decorative element or illustration here */}
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Abstract shapes for background */}
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          right: '5%',
          width: '300px',
          height: '300px',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          borderRadius: '50%',
          filter: 'blur(40px)',
        }}
      />
    </Box>
  );
};

export default Hero;
