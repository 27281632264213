import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Stack, Divider, useTheme } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.light}15 100%)`,
        pt: 8,
        pb: 6,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: `linear-gradient(90deg, transparent 0%, ${theme.palette.primary.main}40 50%, transparent 100%)`,
        },
      }}
    >
      {/* Decorative Elements */}
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '20px',
          height: '20px',
          background: `${theme.palette.primary.main}20`,
          borderRadius: '50%',
          filter: 'blur(20px)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '20%',
          right: '10%',
          width: '40px',
          height: '40px',
          background: `${theme.palette.secondary.main}15`,
          borderRadius: '50%',
          filter: 'blur(25px)',
        }}
      />

      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                  mb: 2,
                  position: 'relative',
                  display: 'inline-block',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -8,
                    left: 0,
                    width: '40%',
                    height: '2px',
                    background: theme.palette.primary.main,
                  }
                }}
              >
                GNC Veri Teknolojileri
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: 3, maxWidth: 300, lineHeight: 1.8 }}
              >
                Kurumsal Danışmanlık Hizmetleri ile işletmenizi geleceğe hazırlıyoruz.
              </Typography>
              <Stack direction="row" spacing={2}>
                {[LinkedInIcon, TwitterIcon, FacebookIcon].map((Icon, index) => (
                  <IconButton
                    key={index}
                    sx={{
                      bgcolor: 'background.paper',
                      boxShadow: 1,
                      '&:hover': {
                        bgcolor: 'primary.main',
                        transform: 'translateY(-3px)',
                        '& svg': { color: 'white' },
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <Icon sx={{ color: 'primary.main' }} />
                  </IconButton>
                ))}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom fontWeight="600">
              HİZMETLERİMİZ
            </Typography>
            <Stack spacing={2}>
              {[
                'Bilgi Güvenliği Danışmanlığı',
                'KVKK Danışmanlığı',
                'Eğitim Hizmetleri'
              ].map((service, index) => (
                <Link
                  key={index}
                  href="#"
                  underline="none"
                  sx={{
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'all 0.2s',
                    '&:hover': {
                      color: 'primary.main',
                      transform: 'translateX(8px)',
                    },
                    '&::before': {
                      content: '""',
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      mr: 2,
                      transition: 'all 0.2s',
                    },
                  }}
                >
                  {service}
                </Link>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom fontWeight="600">
              İLETİŞİM BİLGİLERİ
            </Typography>
            <Stack spacing={3}>
              {[
                { icon: EmailIcon, text: 'info@gncveri.com' },
                { icon: PhoneIcon, text: '+90 (555) 011 87 43' },
              ].map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: 2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.light',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <item.icon sx={{ color: 'primary.main' }} />
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {item.text}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 6, opacity: 0.1 }} />

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{
            opacity: 0.8,
            fontSize: '0.875rem',
          }}
        >
          {new Date().getFullYear()} GNC Veri Teknolojileri. Tüm hakları saklıdır.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
