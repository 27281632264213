import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Container, IconButton, Menu, MenuItem, useScrollTrigger } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState('home');
  const open = Boolean(anchorEl);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const menuItems = [
    { label: 'Ana Sayfa', href: '#home', section: 'home' },
    { label: 'Hizmetlerimiz', href: '#services', section: 'services' },
    { label: 'Hakkımızda', href: '#about', section: 'about' },
    { label: 'İletişim', href: '#contact', section: 'contact' },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 80; // Height of the fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    handleClose();
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = menuItems.map(item => item.section);
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: trigger ? 'background.paper' : 'transparent',
        boxShadow: trigger ? 1 : 'none',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="a"
            href="#home"
            onClick={() => scrollToSection('home')}
            sx={{
              flexGrow: 1,
              color: trigger ? 'primary.main' : 'white',
              textDecoration: 'none',
              fontWeight: 'bold',
              transition: 'color 0.3s ease-in-out',
            }}
          >
            GNC Veri Teknolojileri
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item) => (
              <Button
                key={item.label}
                onClick={() => scrollToSection(item.section)}
                sx={{
                  color: trigger ? 'text.primary' : 'white',
                  mx: 1,
                  opacity: activeSection === item.section ? 1 : 0.8,
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    width: activeSection === item.section ? '100%' : '0%',
                    height: '2px',
                    bgcolor: trigger ? 'primary.main' : 'white',
                    transition: 'all 0.3s ease-in-out',
                    transform: 'translateX(-50%)',
                  },
                  '&:hover': {
                    opacity: 1,
                    '&::after': {
                      width: '100%',
                    },
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{
                color: trigger ? 'text.primary' : 'white',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiPaper-root': {
                  borderRadius: 2,
                  mt: 1.5,
                  boxShadow: 3,
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.label}
                  onClick={() => scrollToSection(item.section)}
                  sx={{
                    minWidth: 180,
                    '&:hover': {
                      bgcolor: 'primary.light',
                      color: 'white',
                    },
                  }}
                >
                  <Typography textAlign="center">{item.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
