import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import TargetIcon from '@mui/icons-material/TrackChanges';
import BuildIcon from '@mui/icons-material/Build';

const About = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        bgcolor: 'background.paper',
        position: 'relative',
      }}
    >
      {/* Background Pattern */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          opacity: 0.05,
          backgroundImage: 'radial-gradient(#2563eb 2px, transparent 2px)',
          backgroundSize: '30px 30px',
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                background: 'linear-gradient(to right, #1e40af, #2563eb)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 4,
              }}
            >
              Hakkımızda
            </Typography>
            
            <Typography variant="body1" paragraph sx={{ color: 'text.secondary', mb: 4 }}>
              GNC Veri Teknolojileri, 2007 tarihinde faaliyetlerine başlamış ve yirmi beş yılı aşkın süredir müşterilerine hizmet vermektedir. Şirketimiz, müşterilerimize verdiği danışmanlık hizmetleri ile KVKK Uyum Sürecini Yönetmek, Kişisel Verilerin Korunması ve Veri Güvenliği konularında uzman kadrosu ile destek olmaktadır.
            </Typography>

            <Box sx={{ mb: 6 }}>
              <Typography variant="h4" gutterBottom sx={{ color: 'primary.main' }}>
                Misyonumuz
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                GNC Veri Teknolojileri olarak misyonumuz, müşterilerimizin iş yapış şekillerini kurumsal kaynaklarını geliştirerek daha verimli, daha güvenli ve daha karlı hale getirmektir.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h4" gutterBottom sx={{ color: 'primary.main' }}>
                Hizmetlerimiz
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                KVKK uyum sürecinde işletmelere yönelik danışmanlık, eğitim ve bilgi güvenliği hizmetleri sunmaktayız. Ayrıca, bilgi işlem altyapı kurulum ve danışmanlık hizmetleri de vermekteyiz.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              elevation={0}
              sx={{
                bgcolor: 'background.default',
                height: '100%',
                p: 4,
                borderRadius: 4,
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 4 }}>
                Neden Biz?
              </Typography>
              
              <List>
                <ListItem sx={{ py: 2 }}>
                  <ListItemIcon>
                    <TimelineIcon sx={{ color: 'primary.main', fontSize: 30 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" gutterBottom>
                        25+ Yıl Tecrübe
                      </Typography>
                    }
                    secondary="Uzman kadromuz ile çeyrek asırdır hizmet veriyoruz"
                  />
                </ListItem>

                <ListItem sx={{ py: 2 }}>
                  <ListItemIcon>
                    <TargetIcon sx={{ color: 'primary.main', fontSize: 30 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" gutterBottom>
                        Çözüm Odaklı Yaklaşım
                      </Typography>
                    }
                    secondary="Müşterilerimizin ihtiyaçlarına özel çözümler üretiyoruz"
                  />
                </ListItem>

                <ListItem sx={{ py: 2 }}>
                  <ListItemIcon>
                    <BuildIcon sx={{ color: 'primary.main', fontSize: 30 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" gutterBottom>
                        Sürekli Gelişim
                      </Typography>
                    }
                    secondary="En güncel bilgi ve uygulamaları takip ediyoruz"
                  />
                </ListItem>
              </List>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
