import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Hero from './Hero';
import Services from './Services';
import About from './About';
import Footer from './Footer';

const Layout = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Box id="home">
        <Hero />
      </Box>
      <Box id="services">
        <Services />
      </Box>
      <Box id="about">
        <About />
      </Box>
      <Box id="contact">
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
